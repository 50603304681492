// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-emsculpt-js": () => import("./../../../src/pages/emsculpt.js" /* webpackChunkName: "component---src-pages-emsculpt-js" */),
  "component---src-pages-emsella-js": () => import("./../../../src/pages/emsella.js" /* webpackChunkName: "component---src-pages-emsella-js" */),
  "component---src-pages-form-success-js": () => import("./../../../src/pages/form-success.js" /* webpackChunkName: "component---src-pages-form-success-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ultraformer-js": () => import("./../../../src/pages/ultraformer.js" /* webpackChunkName: "component---src-pages-ultraformer-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-weight-reduction-clinic-js": () => import("./../../../src/pages/weight-reduction-clinic.js" /* webpackChunkName: "component---src-pages-weight-reduction-clinic-js" */)
}

